<template>
  <div class="createSuit">
    <div class="title">
      {{
        type == "add" || type == "copy"
          ? "创建套装优惠"
          : type == "edit"
          ? "编辑套装优惠"
          : "套装优惠详情"
      }}
    </div>
    <div class="cell1">
      <div class="titles">基本信息</div>
      <div class="form">
        <div class="form-item">
          <div class="label" style="margin-top: -20px">套装名称</div>
          <div style="width: 100%">
            <el-input
              style="width: 100%"
              size="small "
              type="text"
              placeholder="请输入"
              v-model="form.suit_name"
              maxlength="25"
              show-word-limit
            >
            </el-input>
            <div style="font-size: 12px; color: #b0b0b0; margin-top: 4px">
              套装优惠名称将不会展示给买家
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label">套装周期</div>
          <div style="width: 100%; display: flex; align-items: center">
            <el-date-picker
              style="width: 50%"
              size="small "
              v-model="start_time"
              type="datetime"
              prefix-icon="el-icon-date"
              placeholder="选择开始时间"
              default-time="00:00:00"
              value-format="timestamp"
            >
            </el-date-picker>
            <span style="margin: 0 10px">—</span>
            <el-date-picker
              style="width: 50%"
              size="small "
              v-model="end_time"
              type="datetime"
              prefix-icon="el-icon-date"
              default-time="23:59:59"
              placeholder="选择结束时间"
              value-format="timestamp"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="form-item">
          <div class="label" style="margin-top: -80px">套装类型</div>
          <div>
            <el-radio-group
              :disabled="form.status == 1 && type == 'edit'"
              @change="radioChange"
              v-model="form.suit_type"
            >
              <div style="margin-bottom: 10px">
                <el-radio label="2">折扣比率</el-radio>
              </div>
              <div style="font-size: 14px" v-if="form.suit_type == 2">
                购买
                <el-input
                  :disabled="form.status == 1 && type == 'edit'"
                  size="small"
                  style="width: 60px"
                  v-model="form.buy_number"
                ></el-input>
                个商品享受
                <el-input
                  :disabled="form.status == 1 && type == 'edit'"
                  size="small"
                  style="width: 140px"
                  placeholder="1-99"
                  @input="handleChange"
                  v-model="form.discount"
                >
                  <template slot="append">%折扣</template>
                </el-input>
              </div>
              <div style="margin: 10px 0">
                <el-radio label="1">折扣金额</el-radio>
              </div>

              <div
                v-if="form.suit_type == 1"
                style="margin-bottom: 10px; font-size: 14px"
              >
                购买
                <el-input
                  :disabled="form.status == 1 && type == 'edit'"
                  size="small"
                  style="width: 60px"
                  v-model="form.buy_number"
                ></el-input
                >个商品减
                <el-input
                  :disabled="form.status == 1 && type == 'edit'"
                  size="small"
                  style="width: 140px"
                  v-model="form.discount"
                  @input="handleChanges"
                >
                  <template slot="prepend">RM</template>
                </el-input>
              </div>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label" style="margin-top: -20px">购买限制</div>
          <div style="width: 100%">
            <el-input
              :disabled="form.status == 1 && type == 'edit'"
              style="width: 100%"
              size="small "
              v-model="form.buy_limit"
              placeholder="请输入"
            ></el-input>
            <div style="font-size: 12px; color: #b0b0b0; margin-top: 4px">
              买家可购买套装优惠的最大数量。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles">
        <div>套装优惠商品</div>
        <div v-if="goodsList.length != 0">
          <el-button
            style="min-width: 120px; color: #ee4d2d; border-color: #ee4d2d"
            size="mini"
            icon="el-icon-plus"
            @click="addGoods"
            plain
            >添加商品</el-button
          >
        </div>
      </div>
      <div style="font-size: 12px; color: #b0b0b0" v-if="goodsList.length == 0">
        请添加商品到套装优惠。
      </div>
      <div style="font-size: 12px; color: #b0b0b0" v-else>
        套装优惠中的商品必须有一个共同的运输渠道。
      </div>
      <div style="margin-top: 20px" v-if="goodsList.length == 0">
        <el-button
          style="min-width: 120px; color: #ee4d2d; border-color: #ee4d2d"
          size="mini"
          icon="el-icon-plus"
          @click="addGoods"
          plain
          >添加商品</el-button
        >
      </div>
      <div v-if="goodsList.length != 0">
        <el-input
          size="small"
          style="width: 500px; margin-top: 20px"
          placeholder="请输入内容"
          v-model="keyword"
          class="input-with-select"
        >
          <el-select
            style="width: 120px"
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="商品名称" value="1"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="goodsSearch"
          ></el-button>
        </el-input>
        <div style="font-size: 12px; margin: 10px 0; color: #b0b0b0">
          总共
          <span style="color: #000">{{ goodsList.length }}</span>
          个商品
        </div>
        <div class="all-set">
          <div>
            <div>批量设置</div>
            <div style="font-size: 12px; color: #b0b0b0; margin-top: 4px">
              已选择
              <span>{{ checkedCities.length }}</span>
              个商品
            </div>
          </div>
          <div>
            <el-button
              :disabled="checkedCities.length == 0"
              @click="handleBatch(1)"
              size="small"
              plain
              >批量关闭</el-button
            >
            <el-button
              :disabled="checkedCities.length == 0"
              @click="handleBatch(2)"
              size="small"
              plain
              >批量开启</el-button
            >
            <el-button
              :disabled="checkedCities.length == 0"
              @click="handleBatch(3)"
              size="small"
              plain
              >批量删除</el-button
            >
          </div>
        </div>
        <div class="list">
          <div class="list-head">
            <div style="width: 3%">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              ></el-checkbox>
            </div>
            <div style="width: 25%">商品详情</div>
            <div style="width: 15%; display: flex; align-items: center">
              <span style="margin-right: 6px"> 价格</span>
              <!-- <div>
                <div class="triangleT"></div>
                <div class="triangleB"></div>
              </div> -->
            </div>
            <div style="width: 15%">库存</div>
            <div style="width: 15%">运送资料</div>
            <div style="width: 15%">启用/禁用</div>
            <div style="width: 5%">操作</div>
          </div>
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <div
              class="list-content"
              v-for="(item, index) in goodsList"
              :key="item.id"
            >
              <div style="width: 3%">
                <el-checkbox :label="item.id"> &ensp; </el-checkbox>
              </div>
              <div style="width: 25%; display: flex">
                <el-image
                  style="width: 60px; height: 60px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
                <div>
                  <div
                    class="single-hidden"
                    style="font-size: 14px; width: 200px"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    style="
                      font-szie: 12px;
                      color: #b0b0b0;
                      margin-top: 4px;
                      font-size: 14px;
                    "
                  >
                    全球商品货号:{{ item.goods_sn ? item.goods_sn : "-" }}
                  </div>
                </div>
              </div>
              <div style="width: 15%; font-size: 14px">{{ item.price }}</div>
              <div style="width: 15%; font-size: 14px">{{ item.stock }}</div>
              <div style="width: 15%; font-size: 14px">
                {{ item.freight_name }}
              </div>
              <div style="width: 15%">
                <el-switch
                  @change="change"
                  v-model="item.kg"
                  active-color="#13ce66"
                >
                </el-switch>
              </div>
              <div style="width: 5%">
                <div
                  style="
                    width: 36px;
                    height: 36px;
                    border: 1px solid #eee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 16px;
                    background: #fff;
                  "
                  @click="handleDel(index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
      "
    >
      <el-button size="small" plain @click="toBack">取消</el-button>
      <el-button @click="addSuit" size="small" type="primary">确认</el-button>
    </div>
    <MarketingGoods ref="goods" :show="dialogVisible"></MarketingGoods>
  </div>
</template>
<script>
import MarketingGoods from "@/components/goods/MarketingGoods.vue";
import { suitAction, findSuit } from "@/api/marketing.js";
export default {
  components: { MarketingGoods },
  data() {
    return {
      radio: "1",
      dialogVisible: false,
      start_time: "",
      end_time: "",
      keyword: "",
      select: "1",
      goodsList: [],
      goodsListV: [],
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      type: "add",
      form: {
        id: 0,
        suit_name: "",
        start_time: "",
        end_time: "",
        suit_type: "2",
        buy_number: "",
        discount: "",
        buy_limit: "",
        suit_goods: [],
      },
    };
  },
  created() {
    if (sessionStorage.SUITTYPE) {
      this.type = sessionStorage.SUITTYPE;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createSuit") {
              val.meta.bread[3].name =
                this.type == "edit"
                  ? "编辑套装优惠"
                  : this.type == "add" || this.type == "copy"
                  ? "创建套装优惠"
                  : "套装优惠详情";
            }
          });
        }
      });

      // 是否是编辑操作
      if (this.type == "edit" || this.type == "info" || this.type == "copy") {
        this.getInfo();
      }
    } else {
      this.$router.replace("suit");
    }
  },
  methods: {
    getInfo() {
      findSuit({
        id: sessionStorage.SUITID,
      }).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          this.form.suit_type = this.form.suit_type.toString();
          if (this.type == "copy") {
            this.form.id = 0;
          }
          this.start_time = this.form.start_time * 1000;
          this.end_time = this.form.end_time * 1000;
          if (this.form.suit_type == 2) {
            this.form.discount = parseInt(this.form.discount);
          }
          let arr = [];
          this.form.suit_goods.forEach((item) => {
            this.checkedCities.push(item.goods_id);
            this.checkAll = true;
            arr.push({
              id: item.goods_id,
              image: item.goods_image,
              freight_name: item.freight_name,
              price: item.goods_price,
              stock: item.stock,
              title: item.goods_name,
              kg: item.status == 1 ? true : false,
              goods_sn: item.goods_sn,
            });
          });
          this.goodsList = arr;
          this.goodsListV = this.goodsList;
        }
      });
    },
    // 添加活动
    addSuit() {
      if (!this.form.suit_name) {
        this.$message.error("请填写套装名称");
        return false;
      }
      if (!this.start_time || !this.end_time) {
        this.$message.error("请选择开始/结束时间");
        return false;
      }
      this.form.start_time = this.start_time / 1000;
      this.form.end_time = this.end_time / 1000;
      let arr = [];
      this.goodsList.forEach((item) => {
        arr.push({
          goods_id: item.id,
          status: item.kg ? 1 : 2,
        });
      });
      this.form.suit_goods = arr;
      suitAction({
        ...this.form,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(
            this.type == "add" ? "套装优惠创建成功" : "套装优惠编辑成功"
          );
          this.$router.replace("suit");
        }
      });
    },
    // 添加商品
    addGoods() {
      this.dialogVisible = true;
    },
    handleAndGoods(arr) {
      let list = [];
      this.goodsList.forEach((item) => {
        list.push(item.id);
      });
      arr.forEach((item) => {
        if (list.indexOf(item.id) == -1) {
          this.checkedCities.push(item.id);
          this.checkAll = true;
          item.kg = true;
          this.goodsList.push(item);
        }
      });
      this.goodsListV = this.goodsList;
      this.dialogVisible = false;
    },
    change() {
      this.$forceUpdate();
    },
    // 全选
    handleCheckAllChange(val) {
      let arr = [];
      if (val) {
        this.goodsList.forEach((item) => {
          arr.push(item.id);
        });
      }
      this.checkedCities = arr;
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.goodsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.goodsList.length;
    },
    // 批量操作
    handleBatch(type) {
      if (type != 3) {
        this.goodsList.forEach((item) => {
          if (this.checkedCities.indexOf(item.id) != -1) {
            item.kg = type == 2 ? true : false;
          }
        });
      } else {
        // 删除操作
        let arr = this.goodsList.filter((item) => {
          if (this.checkedCities.indexOf(item.id) == -1) {
            return item;
          }
        });

        this.goodsList = arr;
        this.checkedCities = [];
        this.$refs.goods.$data.checkedCities = [];
        this.goodsList.forEach((item) => {
          this.$refs.goods.$data.checkedCities.push(item.id);
        });
        this.checkAll = false;
        this.isIndeterminate = false;
      }

      this.$forceUpdate();
    },
    // 删除
    handleDel(index) {
      if (this.checkedCities.indexOf(this.goodsList[index].id) != -1) {
        this.checkedCities.splice(
          this.checkedCities.indexOf(this.goodsList[index].id),
          1
        );
      }

      this.goodsList.splice(index, 1);
      this.$refs.goods.$data.checkedCities.splice(index, 1);
      this.handleCheckedCitiesChange(this.checkedCities);
    },
    radioChange() {
      this.form.buy_number = "";
      this.form.discount = "";
    },
    // 取消
    toBack() {
      this.$router.go(-1);
    },
    handleChange() {
      let reg = /^([1-9]\d|\d)$/;
      if (!reg.test(this.form.discount) || this.form.discount == 0) {
        this.form.discount = "";
      }
    },
    handleChanges() {
      if (this.form.discount <= 0 || this.form.discount == "-") {
        this.form.discount = "";
      }
    },
    goodsSearch() {
      if (this.keyword != "") {
        let arr = [];
        this.goodsList.forEach((item) => {
          if (item.title.indexOf(this.keyword) != -1) {
            arr.push(item);
          }
        });
        if (arr.length == 0) {
          this.$message.info("搜索的商品不存在");
          return false;
        }
        this.goodsList = arr;
      } else {
        this.goodsList = this.goodsListV;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.createSuit {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 40px;
  .title {
    font-size: 20px;
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .form {
      padding: 0 160px;
      width: 800px;
      margin-top: 40px;
      .form-item {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 20px;
        .label {
          margin-right: 20px;
          width: 70px;
        }
      }
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    .titles {
      font-size: 18px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .all-set {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background: #fafafa;
      font-size: 14px;
    }
    .list {
      margin-top: 20px;
      border: 1px solid #eee;
      font-size: 12px;
      .list-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: #f6f6f6;
        font-size: 14px;
        color: #727c7c;
      }
      .list-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
      }
    }
  }
}
</style>